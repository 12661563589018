import React, { useState, useEffect } from "react";
//import { Link } from 'react';
import axios from "axios";
//import ListItem from "../../ListItem/ListItem";
import "./OrdersPage.css";

import MaterialTable from '@material-table/core';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import AddOrderForm from "../../AddOrderForm/AddOrderForm"
import InvEventForm from "../../InvEventForm/InvEventForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DeleteForm from "../../DeleteForm/DeleteForm";

import CommitOrderForm from "../../CommitOrderForm/CommitOrderForm";

function OrdersPage(props) {
  const organizationId = props.id;
  //console.log("organzaiton id ", organizationId);
  let warehouse = props.id;

  const [list, setList] = useState({
    orders: [],
    items: [],
    organzations: []
  })

  //const [warehouseObject, setWarehouse] = useState({});
  const [warehouseObject] = useState({});

  const [event, setEvent] = useState("");

  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    let mounted = true;
    function getProduct() {
      const payload = {
        organizationId
      };
      axios({
        url: "/orders/" + organizationId,
        method: "GET",
        data: payload
      })
        .then((response) => {
          const resdata = response.data;
          const orders = resdata.orders;
          const customers = resdata.customers;
          const items = resdata.items;
          //console.log("response is: ", resdata);
          if (mounted && orders !== undefined) {
            const [commitedOrders, openOrders] = orders.reduce(([commited, opened], order) => (order.commited === true ? [[...commited, order], opened] : [commited, [...opened, order]]), [[], []]);

            setList({
              orders: openOrders,
              commitedOrders: commitedOrders,
              customers: customers,
              items: items
            });

          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getProduct();
    return () => {
      mounted = false;
    };
  }, [warehouse, organizationId]);

  const columns = [
    {
      title: "Invoice #",
      field: "invoiceNum",
      render: row => (<a href={"/order/" + row._id}>{row.invoiceNum}</a>)
      //customFilterAndSearch: "",
    },
    {
      title: "Customer",
      field: "customerId",
      //render:  row  => (console.log("rowData: ", row, " custumerList: ", list.customers ))
      render: row => (<> {list.customers[list.customers.findIndex((customer) => customer._id === row.customerId)].accountName} </>)
      //render: ({ rowData }) => (<> {console.log("rowData: ", rowData) } </>)

    },
    {
      title: "PO #",
      type: "string",
      field: "PO",
    },
    {
      title: "Deliver Date",
      //type: "date",
      field: "dateFor",
      render: row => (<div> {row.dateFor !== null ? (row.dateFor.substring(0, 10)) : "No Date Set."} </div>)
    },
    {
      title: "Creation Date",
      //type: "date",
      field: "dateMade",
      render: row => (<div> {row.dateMade !== null ? (row.dateMade.substring(0, 10)) : "No Date Set."} </div>)
    },
    {
      title: "Location",
      field: "locationId",
      render: row => (<> {list.customers[list.customers.findIndex((customer) => customer._id === row.customerId)].locations[list.customers[list.customers.findIndex((customer) => customer._id === row.customerId)].locations.findIndex((location) => location._id === row.locationId)].locationName} </>)
      //render: ({ rowData }) => (<> {console.log("row is ", rowData)} </>)

    }
  ]

  const orderColumns = [
    {
      title: "Product",
      field: "productId",
      render: row => (<> {list.items.findIndex((item) => item._id === row.productId) !== -1 ? list.items[list.items.findIndex((item) => item._id === row.productId)].itemName : "Uh-Oh! This is a default Product..."} </>)
      // render: ({ row }) => (<div> row.itemName </div>)
    },
    {
      title: "Quantity",
      field: "qty",

      defaultSort: "desc"
      //render: ({ rowData }) => (<> {console.log("row is ", rowData)} </>)

    }
  ]

  const exitFun = () => {
    setExiting(true);
  }
  
  const stayFunction = () => {
    setExiting(false);
  }

  const handleClose = () => {
    setEvent({
      bool: false,
      event: ""
    });
    setExiting(false);
  };

  return (
    <div>
      <>
        {event ?
          <>
            <Dialog open={event.bool} onClose={exitFun}
              maxWidth="lg">
              <DialogContent>
              {exiting ?
                  <>
                    <div className="exitingPrompt">
                      You are exiting this form. All changes will be lost.
                      <br />
                      <button className="exitButton" onClick={handleClose}> Exit </button>
                      <>   </>
                      <button className="stayButton" onClick={stayFunction}> Stay </button>
                    </div>
                  </> : null
                }
                {
                  event.event === "newOrder" ?
                    <AddOrderForm
                      organizationId={organizationId}
                      event={event}>
                    </AddOrderForm> :
                    null
                }
                {
                  event.event === "delete" ?
                    <DeleteForm
                      organizationId={organizationId}
                      objectName={event.objectName}
                      delURL={event.delURL}>

                    </DeleteForm> :
                    null
                }
                {
                  event.event === "editOrder" ?
                    <AddOrderForm
                      organizationId={organizationId}
                      event={event}
                      edit={true}
                    >

                    </AddOrderForm> :
                    null
                }

                {
                  event.event === "commitOrder" ?
                    <>
                      <CommitOrderForm
                        organizationId={organizationId}
                        event={event}>

                      </CommitOrderForm> </> : null
                }

              </DialogContent>
            </Dialog>

          </> :
          null}
      </>


      <h2 className="pageTitle"> {warehouseObject.warehouseName}
        <div fontSize="0.5px">
          {warehouseObject.lotTracking
            ? <> A Lot tracking warehouse </>
            : null
          }

        </div>
      </h2>

      <MaterialTable
        className="Waretable"
        title="Open Orders"
        data={list.orders}
        columns={columns}
        actions={[
          {
            icon: Add,
            tooltip: 'New Item',
            isFreeAction: true,
            onClick: () => {
              setEvent({
                bool: true,
                event: "newOrder"
              });
              //console.log(event);
            }
          },
          {
            icon: Edit,
            tooltip: 'Edit Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "editOrder",
                orderId: rowData._id,
                PO: rowData.PO,
                dateFor: rowData.dateFor,
                customerId: rowData.customerId,
                locationId: rowData.locationId,
                warehouseId: rowData.warehouseId,
                orderList: rowData.orderList
              });
            }
          },
          {
            icon: ArrowRightAltIcon,
            tooltip: 'Commit Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "commitOrder",
                itemId: rowData._id,
                orderId: rowData._id
              });
            }
          },
          {
            icon: Delete,
            tooltip: 'Delete Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "delete",
                itemId: rowData._id,
                delURL: "deleteOrder/" + rowData._id,
                objectName: "Invoice #" + rowData.invoiceNum
              });
            }
          }
        ]}
        options={{
          sorting: true,
          search: true,
          titleStyle: {
            fontWeight: 'bold',
            fontSize: '4rem'
          },
          headerStyle: {
            backgroundColor: '',
            fontWeight: 'bold',
            fontSize: '2rem'
          },
          rowStyle: {
            backgroundColor: '#EEE',
            fontSize: '2rem'
          },
          title: { backgroundColor: '#EEE' },
          paging: true,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: '#EEE',
                    fontSize: '2rem',
                    padding: "2rem"
                  }}
                >
                  <div>
                    Description: {rowData.rowData.description} <br></br>
                  </div>

                  <MaterialTable
                    className="Waretable"
                    title="Products"
                    //data= {rowData}
                    data={rowData.rowData.orderList}
                    columns={orderColumns}
                    options={{
                      sorting: true
                    }}
                  />
                </div>
              )
            },
          },
        ]}
      />
      <br />
      <MaterialTable
        className="Waretable"
        title="Commited Orders"
        data={list.commitedOrders}
        columns={columns}

        options={{
          search: true,
          titleStyle: {
            fontWeight: 'bold',
            fontSize: '4rem'
          },
          headerStyle: {
            backgroundColor: '',
            fontWeight: 'bold',
            fontSize: '2rem'
          },
          rowStyle: {
            backgroundColor: '#EEE',
            fontSize: '2rem'
          },
          title: { backgroundColor: '#EEE' },
          paging: true,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: '#EEE',
                    fontSize: '2rem',
                    padding: "2rem"
                  }}
                >
                  <div>
                    Description: {rowData.rowData.description} <br></br>
                  </div>

                  <MaterialTable
                    className="Waretable"
                    title="Products"
                    //data= {rowData}
                    data={rowData.rowData.orderList}
                    columns={orderColumns}
                    options={{
                      sorting: true
                    }}
                    detailPanel={[
                      {
                        tooltip: 'Show Details',
                        render: rowData => {
                          return (
                            <div
                              style={{
                                backgroundColor: '#EEE',
                                fontSize: '2rem',
                                padding: "2rem"
                              }}
                            >
                              <div>
                                <table>
                                  <tr>
                                    <th className="lotTable">Lot</th>
                                    <th className="lotTable">Quantity</th>
                                  </tr>
                                  {rowData.rowData.commitedLots.map((lot, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{lot.lot}</td>
                                        <td>{lot.qty}</td>
                                      </tr>
                                    )
                                  })}
                                </table>
                              </div>

                            </div>
                          )
                        },
                      },
                    ]}
                  />
                </div>
              )
            },
          },
        ]}
      />

    </div>
  );
}
export default OrdersPage;