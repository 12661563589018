import React, { useState, useEffect } from "react";

import axios from "axios";
import "./SettingsPage.css";

import FormReport from "../../FormReport/FormReport";

function SettingsPage(props) {
  let organizationId = props.id;
  let bakeryName = props.name;
  //console.log(bakeryName)
  //console.log("props ", props);
  const [headingText, setHeading] = useState("");

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: "",
  });
 
  const [reportingState, setReportingState] = useState({
    report: "",
    state: false,
  });

  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    setHeading(bakeryName + "'s");
    function getBakery() {
      axios
        .get("/organization/" + organizationId, {})
        .then((response) => {
          //console.log(response.data);
          //WILL GET BAKERY DATA WHEN THERE IS DATA
          //console.log(response.data);
          console.log("data has been received: ");
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }

    getBakery();
    //console.log("Used Effect");
  }, [organizationId, bakeryName, reportingState, eventTimeLog]);

  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    console.log("name: ", name, " value ", value, " reportingState ", reportingState);

    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    setReportingState((prevState) => ({
      ...prevState,
      [name]: value,
      state: true,
    }));

    /*
    setTimeLog({
      time: now,
      date: date
    })*/

    // openRunReport();
  }

  return (
    <div>
      <h2 className=""> {headingText} Settings</h2>

      {/*reportingState.state ?
        <>
          <Dialog open={true} onClose={exitFun}>
            <DialogContent>
              <>
                {exiting ?
                  <>
                    <div className="exitingPrompt">
                      You are exiting this form. All changes will be lost.
                      <br />
                      <button className="exitButton" onClick={handleClose}> Exit </button>
                      <>   </>
                      <button className="stayButton" onClick={stayFunction}> Stay </button>
                    </div>
                  </> : null
                }
              </>
              <FormReport
                reportProps={reportingState}
                organizationId={organizationId}
              />
            </DialogContent>
          </Dialog>
        </>
              : null*/}
      <div>Invite Code: {organizationId}</div>
      <br />

      <div>
        <h4 className=""> Reporting </h4>
        <div>
          <select
            className="selectField"
            type="text"
            name="report"
            placeholder="warehouse"
            onChange={handleChange}
          >
            <option value={""}>Select Value</option>
            <option value={"whoBoughtWhat"}>Who Bought What Report</option>
            <option value={"history"}>History</option>
            <option value={"dateHistory"}>Date History</option>
            <option value={"lotSearch"}>Lot Code Lookup</option>
          </select>
        </div>
      </div>
      {reportingState.state ? (
        <>
          <FormReport
            reportProps={reportingState}
            organizationId={organizationId}
          />
        </>
      ) : null}
    </div>
  );
}

export default SettingsPage;
