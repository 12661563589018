import React, { useState, useEffect } from "react";
//import { Link } from 'react';
import axios from "axios";
//import ListItem from "../../ListItem/ListItem";
import "./WarehousePage.css";

import MaterialTable from '@material-table/core';


import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Subtract from '@material-ui/icons/Remove';

import SettingsIcon from '@material-ui/icons/Settings';
//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import AddProductForm from "../../AddProductForm/AddProductForm"
import InvEventForm from "../../InvEventForm/InvEventForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

function WarehousePage(props) {
  let organizationId = props.organizationId;

  let warehouse = props.id;

  let itemTotal = 0;
  let theoItemTotal = 0;

  //console.log("warehouse props:", props);

  const [list, setList] = useState({
    items: [],
    organzations: [],
    orders: []
  })

  /*
  const [historyList, setHistoryList] = useState({
    items:[]
  })*/

  const [warehouseObject, setWarehouse] = useState({});


  const [invEvent, setInvEvent] = useState("");

  const [exiting, setExiting] = useState(false);

  const [warehouseIndex, setWarehouseIndex] = useState(null);

  const [showSettings, setSettings] = useState(false)

  const [userList, setUserList] = useState([{}])

  const [customerList, setCustomerList] = useState([{}]);
  //State for editing data 
  //const [data, setData] = useState({});

  useEffect(() => {
    let mounted = true;
    function getProduct() {
      const payload = {
        organizationId
      };
      axios({
        url: "/warehouse/" + warehouse,
        method: "GET",
        data: payload
      })
        .then((response) => {
          const resdata = response.data;
          const warehouses = resdata.organization[0].warehouses;
          const items = resdata.items.sort(function (a, b) {
            return a.itemName.localeCompare(b.itemName)
          })
          //console.log("response is: ", resdata, "warehouses ", warehouses);
          const dateSortedOrders = resdata.orders.sort(function (a, b) {
            const date1 = new Date(a.dateFor);
            const date2 = new Date(b.dateFor);
            return date1 - date2;
          })
          // resdata.orders
          //console.log("Date Sorted: ", dateSortedOrders);
          if (mounted) {
            setList({
              items: resdata.items,
              orders: dateSortedOrders
            });
            //setData(list.items);
            //setHistoryList({items: resdata.items});
            const lotTrack = warehouses.find(item => item._id === warehouse)
            //console.log("lot Track is ", lotTrack);
            setWarehouse(lotTrack);
            if (!lotTrack.lotTracking) {
              setWarehouseIndex(0);
            }
            setWarehouseIndex(0); // THIS WILL NEED FIX WHEN ITEMS ARE ACROSS MULTIPLE WAREHOUSES
            //console.log("warehouse data received: ", resdata);

            setUserList(resdata.users);
            setCustomerList(resdata.customers);
            //console.log("CUSTOMERS SHOULD BE: ", resdata.customers)

          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getProduct();
    return () => {
      mounted = false;
    };
  }, [warehouse, organizationId]);

  const columns = [
    {
      title: "Item",
      field: "itemName",
      render: rowData => (<a href={"/item/" + rowData._id}> {rowData.itemName} </a>)
    },
    {
      title: "Inventory",
      //field: "invTotal",
      field: "inventory[" + warehouseIndex + "].warehouseInvTotal",
      //render: row  => (<a> {row.inventory[0].warehouseInvTotal} </a>)

    },
    {
      title: "On Order",
      render: row => (
        <>
          {list.orders.forEach((order, index) => {
            if (index === 0) {
              itemTotal = 0;
            }
            if (order.commited === false) {

              order.orderList.forEach((item, index2) => {
                if (item.productId === row._id) {
                  itemTotal = itemTotal + parseInt(item.qty)
                }
              })
            }
          })}
          {itemTotal}
        </>
      )
    },
    {
      title: "Theoretical Inventory",

      render: row => (
        <>
          {//theoItemTotal = eval(row.inventory[0].warehouseInvTotal)
          }
          {//console.log("ATLEAST ITS IN HERE", list.orders)
          }
          {list.orders.length === 0 ?
            <>
              {
                parseFloat(row.inventory[0].warehouseInvTotal)
              }
            </> :
            <>
              {list.orders.forEach((order, index) => {
                if (index === 0) {
                  theoItemTotal = parseFloat(row.inventory[0].warehouseInvTotal)
                }
                //console.log("first theoinv is ", theoItemTotal);
                if (order.commited === false) {
                  order.orderList.forEach((item, index2) => {
                    if (item.productId === row._id) {
                      theoItemTotal = theoItemTotal - parseInt(item.qty)
                      //console.log("itemTotal ", itemTotal, item.qty);
                    }
                  })
                }

              })
              }
              {//theoItemTotal
              }
              {theoItemTotal < 0 ?
                <p style={{ textAlign: "left", backgroundColor: '#FF0000 ', color: "white" }}>{theoItemTotal} </p> :
                theoItemTotal
              }
            </>
          }

        </>
      )
    },
    {
      title: "Ray's Check",
      render: () => (
        <div>
          <input type="checkbox"  />
        </div>
      )
    }
  ]

  const historyColumns = [
    {
      title: "Event",
      field: "event",
      // render: ({ row }) => (<div> row.itemName </div>)
    },
    {
      title: "Date",
      field: "date",
      type: "date",
      defaultSort: "desc"
      //render: ({ rowData }) => (<> {console.log("row is ", rowData)} </>)

    },
    {
      title: "Time",
      field: "time",

      //render: ({ rowData }) => (<> {console.log("row is ", rowData)} </>)

    }
  ]

  const handleClose = () => {
    setInvEvent({
      bool: false,
      event: ""
    });
    setExiting(false);
  };

  const exitFun = () => {
    setExiting(true);
  }

  const stayFunction = () => {
    setExiting(false);
  }


  function handleWarehouseIndex(index) {
    setWarehouseIndex(index);
    //console.log("warehouse Index is; ", warehouseIndex, "index is; ", index);
  }

  function handleSettings() {
    //console.log("SETTINGS HANDLER BEING PRESSED. Settings: ", showSettings);
    setSettings(!showSettings);
  }

  function handleRadioButton(event) {
    let { name, value } = event.target;
    let newValue;
    if (warehouseObject.orderTaking) {
      newValue = false;
    } else {
      newValue = true
    }
    setWarehouse(prevPost => {
      return {
        ...prevPost,
        [name]: newValue
      }
    })
  }

  function handleSettingsSubmit(event) {

  }
  let total;
  let backgroundStyle;

  return (
    <div>
      <>
        {//console.log("USERLIST; ", userList)
        }
        {//console.log(warehouseObject)
        }
        {showSettings ?
          <>
            <Dialog open={showSettings} onClose={handleSettings}>
              <DialogContent>
                <div>
                  SETTINGS
                  <br />
                  Take Orders: <input type="checkbox" value={warehouseObject.orderTaking} name="orderTaking" onClick={handleRadioButton} />
                  <br />
                  <button onClick={handleSettingsSubmit}> Submit </button>

                </div>
              </DialogContent>
            </Dialog>
          </> : null}
        {invEvent ?
          <>
            <Dialog open={invEvent.bool} onClose={exitFun}>
              <DialogContent>
                {exiting ?
                  <>
                    <div className="exitingPrompt">
                      You are exiting this form. All changes will be lost.
                      <br />
                      <button className="exitButton" onClick={handleClose}> Exit </button>
                      <>   </>
                      <button className="stayButton" onClick={stayFunction}> Stay </button>
                    </div>
                  </> : null
                }

                {
                  invEvent.event === "new" ?
                    <AddProductForm
                      organizationId={organizationId}
                      warehouseObject={warehouseObject}
                      lot={warehouseObject.lotTracking}
                      invEvent={invEvent.event}
                      props={warehouse} /> :
                    null
                }

                {
                  invEvent.event === "add" || invEvent.event === "remove" || invEvent.event === "delete" ?
                    <>
                      {//console.log("were inside here")
                      }
                      <InvEventForm
                        props={warehouse}
                        event={invEvent}
                        warehouseObject={warehouseObject}
                        lot={warehouseObject.lotTracking}
                        invItem={invEvent}
                        fromWarehouseBool={true}
                      />
                    </> :
                    null
                }

              </DialogContent>
            </Dialog>

          </> :
          null}
      </>


      <h2 className="pageTitle"> {warehouseObject.warehouseName}
        <div fontSize="0.5px">
          {warehouseObject.lotTracking
            ? <> A Lot tracking warehouse </>
            : null
          }
          {//<SettingsIcon onClick={handleSettings} />
          }
          {
            //<button>Create Order</button>
          }
        </div>
      </h2>

      <MaterialTable
        className="Waretable"
        title="Inventory"
        data={list.items}
        columns={columns}
        /*
        editable={{
          onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);
 
              resolve();
            }, 1000)
          })
        }}*/
        actions={[
          {
            icon: Add,
            tooltip: 'New Item',
            isFreeAction: true,
            onClick: (event) => {
              setInvEvent({
                bool: true,
                event: "new"
              });
              //console.log(invEvent);
            }
          },
          {
            icon: Add,
            tooltip: 'Add To Inventory',
            onClick: (event, rowData) => {
              setInvEvent({
                bool: true,
                event: "add",
                itemId: rowData._id,
                invValue: rowData.inventory[warehouseIndex].warehouseInvTotal,
                location: rowData.inventory[warehouseIndex].location,
                inventoryLots: rowData.inventory[warehouseIndex].invLots,
                itemName: rowData.itemName,
                inventory: rowData.inventory,
                warehouseIndex: warehouseIndex
              });
            }
          },
          {
            icon: Subtract,
            tooltip: 'Remove from Inventory',
            onClick: (event, rowData) => {
              setInvEvent({
                bool: true,
                event: "remove",
                itemId: rowData._id,
                invValue: rowData.inventory[warehouseIndex].warehouseInvTotal,
                location: rowData.inventory[warehouseIndex].location,
                inventoryLots: rowData.inventory[warehouseIndex].invLots,
                itemName: rowData.itemName,
                inventory: rowData.inventory,
                warehouseIndex: warehouseIndex
              });
            }
          },
          /*{
            icon: ArrowRightAltIcon,
            tooltip: 'Move Warehouse',
            onClick: (event, rowData) => {
              setInvEvent({
                bool: true,
                event: "move",
                itemId: rowData._id,
                invValue: rowData.inventory[warehouseIndex].warehouseInvTotal,
                location: rowData.inventory[warehouseIndex].location,
                inventoryLots: rowData.inventory[warehouseIndex].invLots,
                itemName: rowData.itemName,
                inventory: rowData.inventory,
                warehouseIndex: warehouseIndex
              });
            }
          },*/
          {
            icon: Delete,
            tooltip: 'Delete from Inventory',
            onClick: (event, rowData) => {
              setInvEvent({
                bool: true,
                event: "delete",
                itemId: rowData._id,
                invValue: rowData.inventory.warehouseInvTotal,
                inventory: rowData.inventory.invIntakes,
                location: rowData.inventory.location,
                inventoryLots: rowData.inventory[warehouseIndex].invLots,
                itemName: rowData.itemName,
                warehouseIndex: warehouseIndex
              });
            }
          }
        ]}
        options={{
          search: true,
          sorting: true,
          titleStyle: {
            fontWeight: 'bold',
            fontSize: '4rem'
          },
          headerStyle: {
            backgroundColor: '',
            fontWeight: 'bold',
            fontSize: '2rem'
          },
          rowStyle: {
            backgroundColor: '#EEE',
            fontSize: '2rem'
          },
          title: { backgroundColor: '#EEE' },
          paging: false,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              return (

                <div
                  key={rowData.rowData._id}
                  style={{
                    backgroundColor: '#EEE',
                    fontSize: '2rem',
                    padding: "2rem"
                  }}
                >
                  <div>
                    {//console.log("inside row data", rowData)
                    }
                    Total Inventory:  {//rowData.rowData.inventory[0].warehouseInvTotal
                    }
                    {rowData !== undefined ?
                      <>
                        {rowData.rowData.inventory.map((arrays, index) => {
                          return (
                            <>
                              {arrays.warehouse === warehouse ?
                                <>
                                  {//uhm idk
                                    rowData.rowData.inventory[index].warehouseInvTotal
                                  }
                                  {//console.log("index is ", index)
                                  }
                                </>
                                : null}
                            </>
                          )
                        })}
                        <br></br>
                        Description: {rowData.rowData.description} <br></br>
                        Manufacturer: {rowData.rowData.manufacturer} <br></br>
                        SKU: {rowData.rowData.sku} <br></br>
                        {rowData.rowData.inventory[0].warehouseInvTotal > 0 ?
                          <>
                            {warehouseObject.lotTracking ?
                              <>
                                LOTS:
                                <table>
                                  <tbody>
                                    <tr key={rowData.rowData.sku}>
                                      <th className="lotTable">Lot</th>
                                      <th className="lotTable">Quantity</th>
                                      <th className="lotTable">Location</th>
                                    </tr>

                                    {rowData.rowData.inventory.map((arrays, index) => {
                                      return (
                                        <>
                                          {handleWarehouseIndex(index)}
                                          {arrays.warehouse === warehouse ?
                                            <>
                                              {rowData.rowData.inventory[index].invLots.map((lot, index2) => {
                                                return (
                                                  <tr className="lotTable" key={index}>
                                                    <td>{lot.lot}</td>
                                                    <td>{lot.qty}</td>
                                                    <td>{lot.location}</td>
                                                  </tr>

                                                )
                                              })}
                                            </>
                                            : null
                                          }
                                        </>
                                      )
                                    })}
                                    {//console.log("data is ", list.items)
                                    }
                                  </tbody>
                                </table>
                              </>
                              :
                              <>
                                Location: {rowData.rowData.inventory[warehouseIndex].location} <br></br>
                              </>
                            }
                          </> : null
                        }
                      </> : null
                    }
                  </div>

                  <>
                    <div style={{ display: 'none' }}>{total = 0}
                    </div>
                    Open Orders:
                    <table>
                      <tbody>


                        {list.orders.map((order, index) => {
                          return (
                            <>
                              {index === 0 ?
                                <>
                                  <tr id={index}>
                                    <th className="lotTable">Invoice</th>
                                    <th className="lotTable">PO</th>
                                    <th className="lotTable">Customer</th>
                                    <th className="lotTable">Location</th>
                                    <th className="lotTable">Quantity</th>
                                    <th className="lotTable">Date</th>
                                    <th className="lotTable">Missing</th>
                                  </tr>
                                </>
                                : null
                              }
                              {/* {console.log("CUSTOMER LIST: ", customerList)} */}
                              {order.orderList.map((orderList, index2) => {
                                return (
                                  <>
                                    {
                                      orderList.productId === rowData.rowData._id && order.commited === false ?
                                        <>
                                          {//DIV IS USED TO HIDE DECLARED ORDERED TOTAL AT POINT OF RENDERING.
                                          }
                                          <div style={{ display: 'none' }}>
                                            {total = total + orderList.qty}
                                            {//console.log(" Total is: ", rowData.rowData.inventory[0].invLots.reduce((partialSum, a) => partialSum + a.qty, 0), " on orderlist: ", orderList.qty)
                                            }
                                          </div>
                                          {total > rowData.rowData.inventory[0].warehouseInvTotal ?
                                            <>
                                              <tr id={index2} style={{ textAlign: "left", backgroundColor: '#FF0000 ', color: "white" }}>
                                                <td><a href={"/order/" + order._id}>{order.invoiceNum}</a></td>
                                                <td>{order.PO}</td>
                                                <td>
                                                  {customerList.findIndex((customer) => customer._id === order.customerId) > -1 ?
                                                    <>
                                                      {customerList[customerList.findIndex((customer) => customer._id === order.customerId)].accountName
                                                      } {" "}
                                                    </> : "No Customer Set. How did you do that?"}
                                                </td>
                                                <td>

                                                  <>
                                                    {customerList[customerList.findIndex((customer) => customer._id === order.customerId)].locations.find(location => location._id === order.locationId).locationName
                                                    }
                                                  </>
                                                </td>
                                                <td>{orderList.qty}</td>
                                                <td  >{order.dateFor !== null ? order.dateFor.substring(0, 10) : "no date"}</td>
                                                <td>{rowData.rowData.inventory[0].warehouseInvTotal - total > 0 ?
                                                  0 :
                                                  <> {total - rowData.rowData.inventory[0].warehouseInvTotal > orderList.qty ?
                                                    orderList.qty :
                                                    total - rowData.rowData.inventory[0].warehouseInvTotal}
                                                  </>}
                                                </td>
                                              </tr>
                                            </> :
                                            <tr id={index2}>
                                              <td><a href={"/order/" + order._id}>{order.invoiceNum}</a></td>
                                              <td>{order.PO}</td>
                                              <td>
                                                {customerList.findIndex((customer) => customer._id === order.customerId) > -1 ?
                                                  <>
                                                    {customerList[customerList.findIndex((customer) => customer._id === order.customerId)].accountName
                                                    }
                                                  </> : "No Customer Set. How did you do that?"}
                                              </td>
                                              <td>
                                                <>
                                                  {customerList[customerList.findIndex((customer) => customer._id === order.customerId)].locations.find(location => location._id === order.locationId).locationName
                                                  }
                                                </>
                                              </td>
                                              <td>{orderList.qty}</td>
                                              <td>{order.dateFor !== null ? order.dateFor.substring(0, 10) : "no date set"}</td>
                                              <td>{rowData.rowData.inventory[0].warehouseInvTotal - total > 0 ? 0 : total - rowData.rowData.inventory[0].warehouseInvTotal}</td>
                                            </tr>
                                          }
                                        </>
                                        : null
                                    }
                                  </>
                                )
                              })}
                            </>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </>
                  <MaterialTable
                    className="Waretable"
                    title="History"
                    //data= {rowData}
                    data={rowData.rowData.invIntakes}
                    columns={historyColumns}
                    options={{
                      paging: true,
                      sorting: true
                    }}
                    detailPanel={[
                      {
                        tooltip: 'Show Details',
                        render: rowData => {
                          return (
                            <div
                              style={{
                                backgroundColor: '#EEE',
                                fontSize: '2rem',
                                padding: "2rem"
                              }}
                            >
                              <div>
                                Adjustments Made
                                {rowData.rowData.adjustments.map((arrays, index) => {
                                  return (
                                    <>
                                      {arrays.warehouse === warehouse ?
                                        <>
                                          {rowData.rowData[index].adjustments}
                                          {/* {console.log("index is ", index)} */}
                                        </>
                                        : null}
                                    </>
                                  )
                                })}
                                <br></br>

                                <>
                                  {//"User: " + rowData.rowData.userId
                                    //console.log("WTF IS THIS: ", userList[userList.findIndex((user) => user._id === rowData.rowData.userId )].email)
                                  }
                                  { }
                                  {rowData.rowData.userId !== null ?
                                    <> User:
                                      {userList[userList.findIndex((user) => user._id === rowData.rowData.userId)].email}
                                    </> :
                                    "User commited in older Version. Please logout and in of expected user."}
                                  {/* {console.log(userList.findIndex((user) => user._id === rowData.rowData.userId))} */}
                                  <br />

                                  {rowData.rowData.event === "new" ? <> Created </> : null}
                                  {rowData.rowData.event === "add" ? <> {rowData.rowData.event}ed </> : null}
                                  {rowData.rowData.event === "remove" ? <> {rowData.rowData.event}d </> : null}
                                  {rowData.rowData.event === "Commited" ? <> {rowData.rowData.event} to order <a href={"/order/" + rowData.rowData.orderId}>{rowData.rowData.orderId} </a>  </> : null}
                                  <br></br>
                                  LOTS:
                                  <table>
                                    <tbody>
                                      <tr>
                                        {warehouseObject.lotTracking ? <th className="lotTable">Lot</th> : null}
                                        <th className="lotTable">Quantity</th>
                                      </tr>

                                      {rowData.rowData.adjustments.map((arrays, index) => {
                                        return (
                                          <>
                                          {/* {console.log("ARRAYS HERE ", arrays)} */}
                                            <tr className="lotTable" key={index}>
                                              {warehouseObject.lotTracking ? <td>{arrays.lot}</td> : null}
                                              <td>{arrays.value}</td>
                                            </tr>

                                            {//handleWarehouseIndex(index) arrays.warehouse === warehouse
                                            }
                                          </>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </>
                              </div>
                            </div>
                          )
                        },
                      },
                    ]}
                  />
                </div>
              )
            },
          },
        ]}
      />
      < br />
    </div >
  );
}

export default WarehousePage;