import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FormReport.css";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import CustomSelect from "../CustomSelect/CustomSelect";
import ReportGenerate from "../ReportGenerate/ReportGenerate";

function FormReport(props) {
  const organizationId = props.organizationId;
  let custId;
  const reportProps = props.reportProps;

  //console.log("Report props: ", reportProps);
  const [reportParams, setReportParams] = useState({
    startDate: "",
    endDate: "",
    date: "",
    lot:""
  });

  const [dropDownItemOptions, setDropDownItemOptions] = useState([
    {
      value: "",
      id: "",
      error: "",
    },
  ]);

  const [orders, setOrders] = useState([{}]);

  const [customers, setCustomers] = useState([{}]);

  const [allItems, setAllItems] = useState([{}]);

  const [selectedValues, setSelectedValues] = useState({
    productRange1: "",
    productRange2: "",
    product: "",
  });
  //console.log(props);
  //const lotTrack = props.lot;

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: "",
  });

  const [report, setReport] = useState({});
  const [showReport, setShowReport] = useState(false);

  const changeHandler = (value, name) => {
    console.log("value; ", value, " name; ", name, " event ");

    setSelectedValues((prevPost) => {
      return {
        ...prevPost,
        [name]: value.id,
      };
    });
  };

  useEffect(() => {
    let mounted = true;
    function getReport() {
      axios
        .get("/reportData/" + organizationId, {})
        .then((response) => {
          //console.log("data has been received: ", response.data);
          // console.log("RECEIVED: ", response.data.customers);

          let itemOptions = response.data.invItems.map((item) => {
            return {
              value: item.itemName,
              id: item._id,
            };
          });

          setDropDownItemOptions(
            itemOptions.sort(function (a, b) {
              const nameA = a.value.toUpperCase();
              const nameB = b.value.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          );

          setAllItems(
            response.data.invItems.sort(function (a, b) {
              const nameA = a.itemName.toUpperCase();
              const nameB = b.itemName.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          );

          setOrders(response.data.orders);

          let customersArray = response.data.customers;
          // console.log("setting customs as; ", customersArray);
          setCustomers(customersArray);
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }

    getReport();
    return () => {
      mounted = false;
    };
    //console.log("Used Effect");
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    console.log("name ", name, " value ", value)
    if (name === "report" && value !== reportProps.report) {
      setReport({}); // Reset the report state
      setShowReport(false); // Hide the previous report if necessary
    }

    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    setReportParams((prevPost) => {
      return {
        ...prevPost,
        [name]: value || "",
      };
    });
    setTimeLog({
      time: now,
      date: date,
    });
  }

  function onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function generateReport() {

    // console.log(dropDownItemOptions);
    console.log("INSIDE GENERATE")
    if (reportProps.report === "whoBoughtWhat") {
      let index1 = dropDownItemOptions.findIndex(
        (item) => item.id === selectedValues.productRange1
      );
      let index2 = dropDownItemOptions.findIndex(
        (item) => item.id === selectedValues.productRange2
      );

      let x = Math.min(index1, index2); //Lower index for product range
      let itemsForReport = [];
      let ordersForReport = [];

      do {
        console.log("items being looped :", allItems[x]);
        itemsForReport.push(allItems[x]);

        orders.map((order, index) => {
          if (
            order.dateFor > reportParams.startDate &&
            order.dateFor < reportParams.endDate
          ) {
            //console.log("FIND IN ORDER LIST: ", order.orderList.findIndex(product => product.productId = allItems[x]._id))
            // console.log(
            //   "invoice: ",
            //   order.invoiceNum,
            //   order,
            //   " index: ",
            //   customers.findIndex(
            //     (customer) => customer._id === order.customerId
            //   ),
            //   " id ",
            //   order.customerId,
            //   " customerName: ",
            //   customers[
            //     customers.findIndex(
            //       (customer) => customer._id === order.customerId
            //     )
            //   ].accountName
            // );
            if (
              order.orderList.findIndex(
                (product) => product.productId === allItems[x]._id
              ) >= 0
            ) {
              //let index = order.orderList.findIndex(product => product.productId = allItems[x]._id);
              // console.log(
              //   "CURRENT item index: ",
              //   x,
              //   " current item id: ",
              //   allItems[x]._id
              // );
              // console.log(
              //   "WHATS GETTING PUSHED: ",
              //   "ORDER: ",
              //   order,
              //   " index in orderlist: ",
              //   order.orderList.findIndex(
              //     (product) => product.productId === allItems[x]._id
              //   )
              // );
              ordersForReport.push({
                productId: allItems[x]._id,
                productName: allItems[x].itemName,
                dateFor: order.dateFor,
                invoiceNum: order.invoiceNum,
                customerName:
                  customers[
                    customers.findIndex(
                      (customer) => customer._id === order.customerId
                    )
                  ].accountName,
                quantity:
                  order.orderList[
                    order.orderList.findIndex(
                      (product) => product.productId === allItems[x]._id
                    )
                  ].qty,
                commitedLots:
                  order.orderList[
                    order.orderList.findIndex(
                      (product) => product.productId === allItems[x]._id
                    )
                  ].commitedLots,
              });
            }
          }
        });

        // console.log(itemsForReport);
        x = x + 1;
      } while (x <= Math.max(index1, index2));
      setReport(ordersForReport);
      console.log("ordersForReport ", ordersForReport)
    }

    if (reportProps.report === "dateHistory") {
      // console.log("inside Date");
      const result = {};
      allItems.forEach((item) => {
        item.invIntakes.forEach((event) => {
          // Extract the date part from the event's date string
          // console.log("item ", item, " event ", event);
          if (event.date) {
            const eventDate = event.date.split("T")[0];

            // Check if the event date matches the startDate
            if (eventDate === reportParams.date) {
              // console.log("INSIDE DATE MATCH CASE");
              event.adjustments.forEach((adjustment) => {
                // Ensure the item exists in the result object
                if (!result[item.itemName]) {
                  result[item.itemName] = {};
                }

                // Sum the values based on adjustment type
                if (result[item.itemName][event.event]) {
                  result[item.itemName][event.event] += adjustment.value;
                } else {
                  result[item.itemName][event.event] = adjustment.value;
                }
              });
            }
          }
          // console.log("mapping result ", result);
        });
      });
      // console.log("result ", result);
      setReport(result);
    }

    if (reportProps.report === "history") {
      let index1 = dropDownItemOptions.findIndex(
        (item) => item.id === selectedValues.product
      );
      let productForHistory = allItems[index1];
      let invIntakes = productForHistory.invIntakes;

      const result = {};
      invIntakes.forEach((event) => {
        // Extract the date part from the event's date string
        const eventDate = event.date.split("T")[0];

        // Check if the event date matches the startDate
        if (eventDate === reportParams.date) {
          event.adjustments.forEach((adjustment) => {
            // Sum the values based on adjustment type
            if (result[event.event]) {
              result[event.event] += adjustment.value;
            } else {
              result[event.event] = adjustment.value;
            }
          });
        }
      });
      setReport(result);
    }

    if (reportProps.report === "lotSearch") {
      console.log("test ");
      const lotNumber = reportParams.lot; // Get the lot number from input
    
      if (!lotNumber) {
        alert("Please enter a lot number.");
        return;
      }
    
      console.log("Searching for lot number:", lotNumber);
    
      const matchingOrders = orders.filter(order =>
        order.orderList?.some(product =>
          product.commitedLots?.some(lotEntry => lotEntry.lot === lotNumber)
        )
      );
    
      const results = [];
    
      matchingOrders.forEach((order) => {
        order.orderList.forEach((product) => {
          console.log("orderList ", product)
          product.commitedLots.forEach((lotEntry) => {
            if (lotEntry.lot === lotNumber) {
              results.push({
                PO: order.PO,
                customerName: customers.find(c => c._id === order.customerId)?.accountName || "Unknown",
                date: order.dateFor,
                productName: product.productName,//allItems.find(item => item.productId === order.productId)?.itemName || "Unknown Product",
                lot: lotEntry.lot,
                quantity: lotEntry.qty,
              });
            }
          });
        });
      });
    
      console.log("Results:", results);
      setReport(results);
    }
    
    // console.log(
    //   "Start date: ",
    //   reportParams.startDate,
    //   " End Date: ",
    //   reportParams.endDate,
    //   " selected values1: ",
    //   selectedValues.productRange1,
    //   " selected values2: ",
    //   selectedValues.productRange2,
    //   " lower and uppder index: ",
    //   index1,
    //   ", "
    // );

    // let report = [{}];

    setShowReport(true);
  }


  function findOrdersByLot(orders, targetLot) {
    return orders.filter(order => 
        order.orderList.some(product => 
            product.commitedLots.some(lot => lot.lot === targetLot)
        )
    );
}

  function submit(event) {
    event.preventDefault();
    //const { name, value } = event.target;
    const payload = {
      organizationId,
      eventTimeLog,
    };

    axios({
      url: "/reportFormRequest",
      //method: "POST",
      data: payload,
    })
      .then(() => {
        //console.log("data as been sent", payload);
        //setToDash(true);
        //console.log(toDash);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  }

  const exitFun = () => {
    setShowReport(false);
  };

  //console.log(invItem);

  return (
    <div className="">
      {/*
      <Dialog open={showReport} onClose={exitFun}>
        <DialogContent>
          <ReportGenerate
            reportState={reportProps}
            report={report}/>


        </DialogContent>
      </Dialog>
      */}
      {
        //console.log("REPORT PARAMS: ", reportParams)
        // console.log("CUSTOMERS, ", customers)
      }
      {
        //reportProps.report
      }

      {reportProps.report === "whoBoughtWhat" ? (
        <>
          {" "}
          Run Who Bought What Report Form
          <br />
          Start Date:{" "}
          <input
            value={reportParams.startDate}
            name="startDate"
            type="date"
            onChange={handleChange}
          ></input>{" "}
          <br />
          End Date:{" "}
          <input
            value={reportParams.endDate}
            name="endDate"
            type="date"
            onChange={handleChange}
          ></input>{" "}
          <br />
          <CustomSelect
            label="Select Product Range Start"
            searchPlaceholder="Search for Product"
            data={dropDownItemOptions}
            //data={customerOptions.map(customer => customer.value)}
            value={selectedValues.productRange1}
            onChange={changeHandler}
            name="productRange1"
          />
          <CustomSelect
            label="Select Product Range End"
            searchPlaceholder="Search for Product"
            data={dropDownItemOptions}
            //data={customerOptions.map(customer => customer.value)}
            value={selectedValues.productRange2}
            onChange={changeHandler}
            name="productRange2"
          />
        </>
      ) : null}

      {reportProps.report === "lotSearch" ? (
        <>
          {" "}
          Run Lot Search Report Form
          <br />
          <input
            value={reportParams.lot}
            name="lot"
            type="text"
            placeholder="Lot Code "
            onChange={handleChange}
          ></input>{" "}
          <br />
        </>
      ) : null}

      {reportProps.report === "history" ? (
        <>
          {" "}
          History
          <br />
          Date:{" "}
          <input
            value={reportParams.date}
            name="date"
            type="date"
            onChange={handleChange}
          ></input>{" "}
          <br />
          <br />
          <CustomSelect
            label="Select Product Range Start"
            searchPlaceholder="Search for Product"
            data={dropDownItemOptions}
            //data={customerOptions.map(customer => customer.value)}
            value={selectedValues.product}
            onChange={changeHandler}
            name="product"
          />
        </>
      ) : null}

      {reportProps.report === "dateHistory" ? (
        <>
          {" "}
          Date History
          <br />
          <br />
          Date:{" "}
          <input
            value={reportParams.date}
            name="date"
            type="date"
            onChange={handleChange}
          ></input>{" "}
        </>
      ) : null}
      <br />
      <br />
      <button onClick={generateReport}>Generate</button>

      {showReport && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <ReportGenerate reportState={reportProps} report={report} />
        </div>
      )}
    </div>
  );
}

export default FormReport;
